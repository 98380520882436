.bordered > .text {
  margin: 10px;
}

.bordered + .text {
  margin-top: 10px;
}

.text + .text {
  margin-top: 10px;
}
